import React from "react";
import ApplicationBanner from "./components/ApplicationBanner";
import SubscribeNow from "./components/SubscribeNow";

const ApplicationToAdmission = () => {
  return (
    <div>
      <ApplicationBanner />
      <SubscribeNow />
    </div>
  );
};

export default ApplicationToAdmission;
