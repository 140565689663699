import React from "react";
import ExamBlogsImage1 from "../../../images/blog-article/exam-section-image1.jpg";
import { Link, useNavigate } from "react-router-dom";
import DefaultThumbNail from "../../../images/blog-article/default.png";

interface BlogCardwithTitleProps {
  item: any;
}

const BlogCardwithTitle: React.FC<BlogCardwithTitleProps> = ({ item }) => {
  console.log("BlogCardwithTitle", item);
  const navigate = useNavigate();

  const handleBlogClick = () => {
    navigate(
      `/blogs/${item.category?.toLocaleLowerCase()}/${item?.title?.toLocaleLowerCase()}`,
      { state: { questions: item } }
    );
  };
  return (
    <div className="h-full overflow-hidden blog-image-title-container">
      <Link
        // to={`/blogs/${item.category?.toLocaleLowerCase()}/${item?.title?.toLocaleLowerCase()}`}
        to={`/blogs/${item?.topic?.toLocaleLowerCase()}/${item?.slug?.toLocaleLowerCase()}`}
        state={{ from: "BlogCardwithTitle", blogDetail: item }}
        // onClick={handleBlogClick}
      >
        <div className="relative h-full ">
          <div className="image-container">
            <img
              src={item?.imageUrl || DefaultThumbNail}
              alt={item?.title}
              className="object-cover w-full h-96 blog-exam-image"
            />
            <div className="absolute top-0 bottom-0 left-0 right-0 transition duration-300 bg-gray-900 opacity-25 hover:bg-transparent"></div>
          </div>
          <div>
            <div className="absolute bottom-0 left-0 px-4 text-sm text-white ">
              <button className="px-3 py-2 green-bg flex items-center text-sm text-[#ffffff]">
                {item?.topic}
              </button>
              <div className="py-4">
                <h1 className="text-lg font-semibold line-clamp-2 hover:text-[#173CBA]">
                  {item?.title || "Top 10 NIRF Engineering Colleges in India"}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BlogCardwithTitle;
