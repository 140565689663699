import { FC } from "react";
import { Link } from "react-router-dom";

interface IProps {
  heading: string;
  title: string;
  description: string;
  url: string;
}

const PrevOrNextBlog: FC<IProps> = ({ heading, title, description, url }) => {
  return (
    <Link to={url} className="block py-4">
      <div className="flex flex-col gap-4 p-6 text-black rounded xl:12 2xl:px-24 bg-green-light">
        <div className="flex items-center justify-center gap-4">
          <div className="flex-1 flex flex-col gap-1">
            <div className="h-0.5 bg-pink"></div>
            <div className="h-0.5 bg-green"></div>
            <div className="h-0.5 bg-blue"></div>
          </div>
          <div className="shrink-0 flex items-center gap-1">
            <span className="text-4xl text-yellow">[</span>
            <span className="text-xl font-bold">{heading}</span>
            <span className="text-4xl text-yellow">]</span>
          </div>
          <div className="flex-1 flex flex-col gap-1">
            <div className="h-0.5 bg-pink"></div>
            <div className="h-0.5 bg-green"></div>
            <div className="h-0.5 bg-blue"></div>
          </div>
        </div>
        <div className="relative max-xl:pb-24 xl:pr-64">
          <img
            src="/images/courses/artificial.png"
            className="rounded"
            alt={title}
          />
          <div className="bg-yellow-light rounded mx-8 my-4 p-4 absolute left-0 xl:left-[25%] 2xl:left-[40%] right-0 bottom-0 xl:bottom-1/2 xl:translate-y-[60%]">
            <h1 className="text-xl font-bold text-green line-clamp-2">
              {title}
            </h1>
            <p className="text-base text-pink line-clamp-4">{description}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PrevOrNextBlog;
