import React from "react";

const ApplicationBanner = () => {
  return (
    <div>
      <div className="college-intermediate-banner-section">
        <div className="bg-gradient-to-r from-[#0B3D8C] to-[#06AFA9]">
          <div className="container max-w-screen-2xl h-[500px] text-white py-8 md:py-36">
            <div className="flex flex-col md:flex-row gap-8">
              <div className="relative w-full md:w-[70%] flex flex-col justify-center leading-10 ">
                <h3 className="text-4xl md:text-4xl min-max-xss:mt-6 min-max-xs:mt-6 font-bold line-clamp-2">
                  Unlock Your Next Chapter
                </h3>
                <p className="text-lg md:text-xl mt-4 mb-auto line-clamp-3">
                  Explore colleges, exams & more. Apply directly.
                  <br />
                  Simplify your education journey.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationBanner;
