import { FC } from "react";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";
import { Link } from "react-router-dom";
import { getBlogOrNewsUrl } from "../../utils";
import Logo from "../../../../images/navbar/newLogo.svg";
import moment from "moment";
import DefaultThumbNail from "../../../../images/blog-article/default.png";

interface IProps {
  data?: any;
}

interface ISlideData {
  data: any;
}

const Banner: FC<IProps> = ({ data }) => {
  const slideData = data[0]?.topArticlesAndBlogsAndLatestNews?.subcategories[
    "Articles & Blogs"
  ].results.slice(0, 4);
  const slides = slideData.map((item: ISlideData) => <Slide data={item} />);

  return (
    <CustomSwiper
      items={slides}
      autoplay={true}
      loop={true}
      delay={3000}
      speed={5000}
    />
  );
};

export default Banner;

const Slide: FC<ISlideData> = ({ data }) => {
  const formattedDate = (date: any) => moment(date).format("MMMM D, YYYY");
  return (
    <>
      <section className="w-full h-[25rem] flex flex-col md:flex-row items-center bg-gray-100">
        <div className="hidden w-full h-full md:w-1/2 md:block">
          <img
            src={data?.imageUrl ? data?.imageUrl : { DefaultThumbNail }}
            alt={data?.title}
            className="object-cover w-full h-full"
          />
        </div>

        <div className="flex flex-col justify-center w-full p-8 md:w-1/2">
          <div className="inline-block">
            <span
              className={`inline-block text-white px-3 py-1 rounded-xl mb-4 bg-green`}
            >
              {data?.category}
            </span>
          </div>

          <h1 className="mb-4 text-3xl font-bold text-gray-800 md:text-4xl line-clamp-2">
            {data?.title}
          </h1>

          <p className=" text-base text-gray-600 md:text-lg line-clamp-2">
            {data?.description}
          </p>

          <img
            src={Logo}
            alt="logo"
            className="object-contain w-[150px] h-[70px]"
          />
          <p className="mb-3 text-base text-gray-600 md:text-lg line-clamp-1">
            {formattedDate(data?.publishedDate)}{" "}
          </p>

          <Link to={getBlogOrNewsUrl(data)} className="inline-block w-auto">
            <button className="flex items-center px-2 py-2 text-white bg-[#173CBA] shadow-lg hover:bg-[#173CBA] rounded-xl">
              Read More
              <div className="pl-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="currentColor"
                  className="flex items-center mx-auto h-full arrow-icon"
                >
                  <path
                    d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </button>
          </Link>
        </div>
      </section>
    </>
  );
};
