import React from "react";
import Arrow from "../../images/Arrow.svg";
import CustomSwiper from "../../components/Slider/CustomSwiper";
import LeadGenCtaBtn from "../../features/lead-generation/LeadGenCtaBtn";

interface AbroadCardProps {
  collegeLogoOne: string;
  collegeLogoTwo: string;
  collegeImage: string;
}
const AbroadCardData = [
  {
    collegeLogoOne: "/images/semesterAbroad/srm.png",
    collegeLogoTwo: "/images/semesterAbroad/uc-davis.png",
    collegeImage: "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Interesting%20Programs%20for%20you/SRM.jpg",
  },
  {
    collegeLogoOne: "/images/semesterAbroad/manipal.png",
    collegeLogoTwo: "/images/semesterAbroad/university-of-melbourne.png",
    collegeImage: "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Interesting%20Programs%20for%20you/Manipal.jpg",
  },
  {
    collegeLogoOne: "/images/semesterAbroad/vit.png",
    collegeLogoTwo: "/images/semesterAbroad/purde.png",
    collegeImage: "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Interesting%20Programs%20for%20you/VIT.jpg",
  },
  {
    collegeLogoOne: "/images/semesterAbroad/amrita.png",
    collegeLogoTwo: "/images/semesterAbroad/new-mexico.png",
    collegeImage: "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Interesting%20Programs%20for%20you/Amir.jpg",
  },
  {
    collegeLogoOne: "/images/semesterAbroad/sastra.png",
    collegeLogoTwo: "/images/semesterAbroad/wisconsin.png",
    collegeImage: "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Interesting%20Programs%20for%20you/Sastra%20university.jpg",
  },
  {
    collegeLogoOne: "/images/semesterAbroad/thapar.png",
    collegeLogoTwo: "/images/semesterAbroad/trinity.png",
    collegeImage: "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Interesting%20Programs%20for%20you/Thapar.jpg",
  },
  {
    collegeLogoOne: "/images/semesterAbroad/manav-rachna.png",
    collegeLogoTwo: "/images/semesterAbroad/nottingham-trent.png",
    collegeImage: "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Interesting%20Programs%20for%20you/manav%20rachn.jpg",
  },
  {
    collegeLogoOne: "/images/semesterAbroad/rv-university.png",
    collegeLogoTwo: "/images/semesterAbroad/michigan-state-univ.png",
    collegeImage: "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/Interesting%20Programs%20for%20you/rv%20university%20bangalore.jpg",
  },
];

const SemesterAbroadProgram: React.FC = () => {
  const slideTrackContent = AbroadCardData.map((card: AbroadCardProps) => (
    <div className="text-black px-2 py-4 rounded-xl border border-grey-3 flex flex-col gap-4">
      <div className="flex  items-center justify-between">
        <img src={card.collegeLogoOne} alt="" className="h-8" />
        <img src={card.collegeLogoTwo} alt="" className="h-8" />
      </div>
      <img src={card.collegeImage} alt="" className="flex rounded-lg w-[325px] h-[260px]" />
      <div className="flex flex-row items-stretch gap-1.5">
        <div className="rounded-md flex items-center justify-center text-center border-yellow border bg-yellow-light p-1.5 w-1/2 text-sm font-semibold">
          Semester Abroad Program (SAP)
        </div>
        <div className="rounded-md flex items-center justify-center text-center border-pink border bg-pink-light p-1.5 w-1/2 text-sm font-semibold">
          Twinning Programme
        </div>
      </div>
      <LeadGenCtaBtn
        pageName="course-landing"
        ctaName="study-abroad-find-out-admission-process"
        className="w-full flex items-center justify-between bg-blue py-3 px-4 rounded-md text-white text-sm"
      >
        <span>Find out Admission Process</span>
        <div className="cta flex items-center justify-center">
          <img src={Arrow} className="arrow-icon" />
        </div>
      </LeadGenCtaBtn>
    </div>
  ));

  return (
    <div className="py-10">
      <div className="relative container flex flex-col gap-10">
        <h3 className="text-3xl text-black md:text-5xl text-center font-bold">
          Interesting Programs for You
        </h3>
        <div className="flex justify-center">
          <CustomSwiper
            items={slideTrackContent}
            navigation={"bottom"}
            autoplay={true}
            breakpoints={{
              320: { slidesPerView: 1, spaceBetween: 5 },
              640: { slidesPerView: 2, spaceBetween: 10 },
              768: { slidesPerView: 2, spaceBetween: 10 },
              1024: { slidesPerView: 3, spaceBetween: 10 },
              1080: { slidesPerView: 4, spaceBetween: 10 },
            }}
            progressBar={true}
          />
        </div>
      </div>
    </div>
  );
};

export default SemesterAbroadProgram;
