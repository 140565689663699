import React, { useState, useRef, useEffect } from "react";
import logo from "../../images/navbar/College Mentor_Final_Logo-01.png";
import chevronDown from "../../images/navbar/chevron-down.png";
import magnifier from "../../images/navbar/Magnifer.png";
import "./NavBar.scss";
import SearchBox from "../searchbox/SearchBox";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import UserIcon from "../../images/authentication/user-circle-icon.svg";
import UserAvatar from "../UserAvatar";
import data from "../contact-bar/DataJson3";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const menuItems = [
  "Home",
  "College",
  "Courses",
  "Exams",
  "Careers",
  "Study Abroad",
  "Blogs",
  "Our Services",
];

export default function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [activeMenu, setActiveMenu] = useState<string | null>(null);
  const [activeSubmenu, setActiveSubmenu] = useState<string | null>(null);
  const [expandedSubmenu, setExpandedSubmenu] = useState<string | null>(null);

  const handleMobileMenuClick = (menuItem: string) => {
    if (expandedSubmenu === menuItem) {
      setExpandedSubmenu(null);
    } else {
      setExpandedSubmenu(menuItem);
    }
  };
  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
    if (isMenuOpen) {
      setActiveMenu(null);
      setActiveSubmenu(null);
    }
  };

  const handleMenuClick = (menuItem: string) => {
    setActiveMenu((prev) => (prev === menuItem ? null : menuItem));
    setActiveSubmenu(null);
  };

  const handleSubmenuClick = (submenuItem: string) => {
    setActiveSubmenu((prev) => (prev === submenuItem ? null : submenuItem));
  };
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isMenuOpen]);
  const renderItems = (menuData: any) => {
    return Object.entries(menuData).map(([key, value], index) => {
      if (Array.isArray(value)) {
        return (
          <div key={key} className={``}>
            <h4
              className={`font-bold superheadermobile py-4 cursor-pointer border-b border-b-[#C2F1E6] whitespace-nowrap flex items-center justify-between px-2 ${activeMenu === key
                ? "bg-[#173CBA] text-[#ffffff]"
                : "text-[#173CBA]"
                }`}
              onClick={() => handleMenuClick(key)}
            >
              {key}
              <span
                className={`inline transition-transform duration-200 ${activeMenu === key ? "transform rotate-180 text-white" : ""
                  }`}
              >
                <svg
                  width="18"
                  height="12"
                  viewBox="0 0 18 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 2.85748L15.8823 0.739746L9.45269 7.16932L3.02312 0.739746L0.905384 2.85748L9.45269 11.4048L18 2.85748Z"
                    fill={activeMenu === key ? "#FFFFFF" : "#201F1F"}
                  />
                </svg>
              </span>
            </h4>
            {activeMenu === key && (
              <ul className="ml-4 list-none submegamenumobile">
                {value.map((item, idx) => (
                  <li key={idx} className="py-1 listtabsmobile">
                    <a href={item.url}>{item.name}</a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        );
      }

      if (typeof value === "object" && value !== null) {
        return (
          <div key={key}>
            <h4
              className={`font-bold superheadermobile py-4 cursor-pointer border-b border-b-[#C2F1E6]  whitespace-nowrap flex items-center justify-between px-2 ${activeMenu === key
                ? "bg-[#173CBA] text-[#ffffff]"
                : "text-[#173CBA]"
                }`}
              onClick={() => handleMenuClick(key)}
            >
              {key}
              <span
                className={`inline transition-transform duration-200 ${activeMenu === key ? "transform rotate-180 text-white" : ""
                  }`}
              >
                <svg
                  width="18"
                  height="12"
                  viewBox="0 0 18 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 2.85748L15.8823 0.739746L9.45269 7.16932L3.02312 0.739746L0.905384 2.85748L9.45269 11.4048L18 2.85748Z"
                    fill={activeMenu === key ? "#FFFFFF" : "#201F1F"}
                  />
                </svg>
              </span>
            </h4>
            {activeMenu === key && (
              <div className="ml-4">
                {Object.entries(value).map(([subKey, subValue]) => (
                  <div key={subKey}>
                    <h4
                      className="py-1 font-bold cursor-pointer subheadermobile"
                      onClick={() => handleSubmenuClick(subKey)}
                    >
                      {subKey}
                    </h4>
                    {activeSubmenu === subKey && Array.isArray(subValue) && (
                      <ul className="ml-4 list-none submegamenumobile">
                        {subValue.map((item, idx) => (
                          <li key={idx} className="py-1 listtabsmobile">
                            <a href={item.url}>{item.name}</a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      }

      return null;
    });
  };

  const { otpSent, error, isAuthenticated, token, user } = useSelector(
    (state: RootState) => state.auth
  );

  const navigate = useNavigate();

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  let currentUser = localStorage.getItem("user");

  return (
    <div className="main-nav">
      <div
        className={`nav-bar-section py-[25px] ${isMenuOpen ? "fixed inset-0 z-20 bg-white" : ""
          }`}
      >
        <div className="container flex items-center justify-between mx-auto">
          <button
            className="block translate-y-1 md:hidden"
            onClick={toggleMenu}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-menu"
            >
              <line x1="3" y1="12" x2="21" y2="12"></line>
              <line x1="3" y1="6" x2="21" y2="6"></line>
              <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
          </button>

          <div className="logo-section">
            <a href="/" className="main-logo">
              <img src={logo} alt="logo" />
            </a>
          </div>

          <div className="flex md:justify-between md:items-center md:gap-[16px]">
            <SearchBox />
          </div>

          <div className="menu-and-button-section flex md:gap-[25px] md:justify-between items-center">
            <div
              className={`hidden md:block ${isMenuOpen ? "block" : "hidden"
                } md:flex md:gap-[25px] md:justify-between`}
            >
              <nav className="items-center navbar">
                <ul className="nav-list md:gap-[8px] lg:gap-[16px] px-4">
                  {menuItems.map((item, index) => (
                    <li key={index} className="ml-4 truncate nav-item">
                      <a
                        href={
                          item.toLowerCase() === "home"
                            ? "/"
                            : item.toLowerCase() === "study abroad"
                              ? "/study-abroad"
                              : `/${item.toLowerCase()}`
                        }
                        className="nav-link flex items-center justify-center hover:bg-[#173CBA] hover:text-white px-2 py-1"
                      >
                        {item}
                        {item === "Our Services" && (
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M14.0324 6.15752C13.9626 6.08722 13.8797 6.03143 13.7883 5.99335C13.6969 5.95527 13.5989 5.93567 13.4999 5.93567C13.4009 5.93567 13.3028 5.95527 13.2114 5.99335C13.12 6.03143 13.0371 6.08722 12.9674 6.15752L9.53236 9.59251C9.46264 9.66281 9.37969 9.7186 9.28829 9.75668C9.1969 9.79475 9.09887 9.81436 8.99986 9.81436C8.90085 9.81436 8.80282 9.79475 8.71143 9.75668C8.62004 9.7186 8.53708 9.66281 8.46736 9.59251L5.03236 6.15752C4.96264 6.08722 4.87969 6.03143 4.78829 5.99335C4.6969 5.95527 4.59887 5.93567 4.49986 5.93567C4.40085 5.93567 4.30282 5.95527 4.21143 5.99335C4.12003 6.03143 4.03708 6.08722 3.96736 6.15752C3.82767 6.29804 3.74927 6.48813 3.74927 6.68627C3.74927 6.88441 3.82767 7.07449 3.96736 7.21502L7.40986 10.6575C7.83174 11.0789 8.40361 11.3155 8.99986 11.3155C9.59611 11.3155 10.168 11.0789 10.5899 10.6575L14.0324 7.21502C14.1721 7.07449 14.2505 6.88441 14.2505 6.68627C14.2505 6.48813 14.1721 6.29804 14.0324 6.15752Z"
                                fill="#333333"
                              />
                            </svg>
                          </span>
                        )}
                      </a>
                    </li>
                  ))}
                  <li className="hidden lg:block">
                    <a
                      className="fa fa-whatsapp text-green-500 scale-[120%]"
                      href="https://api.whatsapp.com/send?text=hi&phone=917997166666"
                      target="_blank"
                    ></a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="flex md:justify-between md:items-center md:gap-[16px]">
              {!currentUser ? (
                <a href="/secure/login">
                  <button
                    className="py-2.5 px-2 md:px-6 blue-color button-styles text-sm font-semibold rounded-full duration-200"
                  // onClick={(e) => navigate("/secure/login")}
                  >
                    login
                  </button>
                </a>
              ) : (
                ""
              )}
            </div>

            {currentUser && (
              <UserAvatar
                link={"dashboard/my-profile"}
                logoutUrl={"secure/login"}
              />
            )}
          </div>
        </div>
        {/* Mobile Menu */}
        <div
          ref={menuRef}
          className={`mobilemenu fixed inset-0 z-10000 overflow-auto w-full mt-6 mb-4 pb-6 z-20pl-4 bg-[#ffffff] ${isMenuOpen ? "block" : "hidden"
            } md:hidden`}
        >
          <div className="flex justify-between p-4">
            <a href="/" className="logo-section main-logo">
              <img src={logo} alt="logo" />
            </a>
            <button className="block" onClick={toggleMenu}>
              <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.743563 1.04983C0.255401 1.55426 0.255401 2.37209 0.743563 2.87653L5.22499 7.50731L0.743563 12.1382C0.255401 12.6426 0.255401 13.4605 0.743563 13.9649C1.23171 14.4693 2.02318 14.4693 2.51133 13.9649L6.99274 9.33398L11.4742 13.9649C11.9624 14.4693 12.7539 14.4693 13.242 13.9649C13.7301 13.4605 13.7301 12.6426 13.242 12.1382L8.76049 7.50731L13.242 2.87654C13.7301 2.37212 13.7301 1.55427 13.242 1.04985C12.7537 0.545416 11.9624 0.545416 11.4742 1.04985L6.99274 5.68063L2.51133 1.04983C2.02318 0.545403 1.23171 0.545403 0.743563 1.04983Z"
                  fill="#0F0F0F"
                />
              </svg>
            </button>
          </div>
          <nav>
            <ul className="flex flex-col justify-start gap-4 p-4 list-none">
              <li key={"home"} className="">
                <div className="whitespace-nowrap">
                  <h4
                    className="superheader font-bold py-4 cursor-pointer whitespace-nowrap flex items-center justify-between border-b border-b-[#C2F1E6]"
                    onClick={() => handleMobileMenuClick("home")}
                  >
                    Main Menu
                    <span
                      className={`inline transition-transform duration-200 ${expandedSubmenu === "home"
                        ? "transform rotate-180 text-white"
                        : ""
                        }`}
                    >
                      <svg
                        width="18"
                        height="12"
                        viewBox="0 0 18 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18 2.85748L15.8823 0.739746L9.45269 7.16932L3.02312 0.739746L0.905384 2.85748L9.45269 11.4048L18 2.85748Z"
                          fill="#201F1F"
                        />
                      </svg>
                    </span>
                  </h4>
                </div>

                {/* Toggle the submenu based on the active state */}
                {expandedSubmenu === "home" && (
                  <ul className="ml-4 list-none">
                    {menuItems.map((item, index) => (
                      <li key={index} className="py-2">
                        <a
                          href={item.toLowerCase() === "home"
                            ? "/"
                            : item.toLowerCase() === "study abroad"
                              ? "/study-abroad"
                              : `/${item.toLowerCase()}`} // Fix to call the function with parentheses
                          className={`font-bold superheadermobile py-4 cursor-pointer border-b border-b-[#C2F1E6] whitespace-nowrap flex items-center justify-between px-2 ${activeMenu === "home"
                            ? "bg-[#173CBA] text-[#ffffff]"
                            : "text-[#173CBA]"
                            }`}
                        >
                          {item}
                        </a>
                      </li>
                    ))}
                    <li className="py-2">
                      <a
                        href={`/college-mentor-scholarship`}
                        className={`font-bold superheadermobile py-4 cursor-pointer border-b border-b-[#C2F1E6] whitespace-nowrap flex items-center justify-between px-2 ${activeMenu === "home"
                          ? "bg-[#173CBA] text-[#ffffff]"
                          : "text-[#173CBA]"
                          }`}
                      >
                        College Mentor Scholarship Test - 1Cr
                      </a>
                    </li>
                  </ul>
                )}
              </li>

              {Object.entries(data).map(([key, value], index) => (
                <li key={index} className="">
                  <div className="whitespace-nowrap">
                    <h4
                      className="superheader font-bold py-4 cursor-pointer whitespace-nowrap flex items-center justify-between border-b border-b-[#C2F1E6]"
                      onClick={() => handleMobileMenuClick(key)}
                    >
                      {key}
                      <span
                        className={`inline transition-transform duration-200 ${expandedSubmenu === key
                          ? "transform rotate-180 text-white"
                          : ""
                          }`}
                      >
                        <svg
                          width="18"
                          height="12"
                          viewBox="0 0 18 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18 2.85748L15.8823 0.739746L9.45269 7.16932L3.02312 0.739746L0.905384 2.85748L9.45269 11.4048L18 2.85748Z"
                            fill="#201F1F"
                          />
                        </svg>
                      </span>
                    </h4>
                  </div>

                  {expandedSubmenu === key && (
                    <ul className="ml-4 list-none ">{renderItems(value)} </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
      {isMenuOpen && (
        <div className="fixed inset-0 z-10 bg-black opacity-50"></div>
      )}
    </div>
  );
}
