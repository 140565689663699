import { Description } from "@mui/icons-material";
import { title } from "process";
import MechanicalEngImage from "../../../../images/courses/meet-top-mentors.png";
import PinkArrow from "../../../../images/courses/course-details/pinkarrow.svg";
import type { SVGProps } from "react";

import React, { useState, useEffect } from "react";
import { useCourseDetails } from "../../../../screens/course-details/CourseDetailsContext";
export function MingcuteRightLine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10px"
      height="10px"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
        <path
          fill="currentColor"
          d="M15.707 11.293a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 1 1-1.414-1.414l4.95-4.95l-4.95-4.95a1 1 0 0 1 1.414-1.414z"
        ></path>
      </g>
    </svg>
  );
}

const MechanicalEngContent = [
  {
    arrow: PinkArrow,
    title: "What is Mechanical Engineering",
    Description:
      "  <p>Mechanical Engineering is a field of engineering which deals with machines, their production, design and operation. one of the specializations of engineering which deals with machines; their production, design, and operation. Mechanical Engineers play a vital role in various industries such as energy conversion, biotechnology, computers, electronics, aerospace, and countless others.</p><p>Mechanical Engineering is a sub-discipline of Engineering and involves taking a product from an idea to its end consumer. This requires them to have an understanding of the environment that any product or system, or its parts, will have to encounter, thereby designing them accordingly keeping in mind factors like durability, aesthetics, and functionality.</p>",
    videoLink: "https://www.youtube.com/embed/T6oKlQoHrIg?autoplay=1",
  },
];
const MechanicalEng: React.FC = () => {
  const { data } = useCourseDetails();
  const { courseTitle = "", about } = data || {};

  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayVideo = () => {
    setIsPlaying(true);
  };
  return (
    <>
      <div className="flex flex-row items-start relative" id="about">
        <div className="flex flex-col bg-[#fff]">
          {MechanicalEngContent.map((content) => (
            <>
              <div className="flex max-md:w-full">
                <h3 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
                  {` What is ${courseTitle}?`}
                </h3>
              </div>
              <div className="flex flex-col md:flex-row py-5 px-9 gap-8 lg:gap-14 items-center">
                <div className="flex flex-col gap-4 lg:gap-16 w-full ">
                  <div className="flex flex-col">
                    <div className="text-base font-light text-[#747474] leading-7" />
                    <div
                      className="flex flex-col px-2 py-8 md:py-5 md:px-9 gap-6 blog-content"
                      dangerouslySetInnerHTML={{ __html: about }}
                    />
                  </div>
                </div>

                {/* <div className="flex flex-col items-center justify-center w-full lg:w-1/3 relative z-0 md:hidden max-md:hidden">
                  {isPlaying ? (
                    <div
                      className="relative w-full h-0"
                      style={{ paddingBottom: "56.25%" }}
                    >
                      <iframe
                        width="100%"
                        height="100%"
                        src={content.videoLink}
                        title="YouTube Video"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        className="absolute inset-0 w-full h-full rounded-lg left-0 top-0"
                      ></iframe>
                    </div>
                  ) : (
                    <>
                      <button
                        className="watch-button"
                        onClick={handlePlayVideo}
                      ></button>
                      <img
                        src={MechanicalEngImage}
                        alt="Image"
                        className="w-full h-full object-cover rounded-lg cursor-pointer"
                        onClick={handlePlayVideo}
                      />
                    </>
                  )}
                </div> */}
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default MechanicalEng;
