import React, { useEffect, useState } from "react";
import BlogItemImage from "../../../images/blog-article/BlogitemImage.png";
import ShareIcon from "../../../images/blog-article/share-icon.svg";
import MeatBallMenuIcon from "../../../images/blog-article/meatballs-menu.svg";
import "./BlogListItem.scss";
import moment from "moment";
import config from "../../../util/config";
import { useLocation, useParams } from "react-router-dom";
import DefaultThumbNail from "../../../images/blog-article/default.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faFacebookF,
  faLinkedin,
  faLinkedinIn,
  faXTwitter,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faShare } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from "react-share";

import Popover from "../../../components/popover/Popover";
import BlogShareFloatingMenu from "./BlogShareFloatingMenu";
import SocialMediaMenuList from "./SocialMediaMenuList";
interface BlogListItemProps {
  parentFunction: (event: React.MouseEvent<HTMLButtonElement>) => void;
  blogItem: any;
}

const BlogListItem: React.FC<BlogListItemProps> = ({
  parentFunction,
  blogItem,
}) => {
  const location = useLocation();
  let { topicSlug } = useParams();

  const [showSocialMenus, setShowSocialMenu] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const hover = (e: any) => {
    parentFunction(blogItem);
  };
  const handleShowSocialDropdown = () => {
    setShowSocialMenu(!showSocialMenus);
  };
  const formattedDate = (date: any) =>
    moment(date).format("MMM D, YYYY | h:mm A");
  const [link, setLink] = useState(
    `/blogs/${
      blogItem?.newsSubTopic?.slug || blogItem?.newsTopic?.slug || "category"
    }/${blogItem?.slug?.toLowerCase()}`
  );

  return (
    <div
      className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 gap-y-4 md:gap-4 group blog-page-card"
      onMouseEnter={(item) => hover(item)}
    >
      <div className="">
        <img
          src={blogItem?.imageUrl || DefaultThumbNail}
          width="100%"
          alt={blogItem?.title}
          className="w-full h-[240px] md:w-[251px] md:h-[172px]"
        />
      </div>
      <div className="flex flex-col col-span-3">
        <h3 className="mb-1">
          <Link
            to={`/blogs/${topicSlug}/${blogItem?.slug?.toLocaleLowerCase()}`}
            state={{
              from: "BlogListItem",
              blogDetail: blogItem,
            }}
            className="text-xs md:text-base"
          >
            <p className="text-lg font-bold cursor-pointer hover:text-[#173CBA]">
              {blogItem?.title || "Important tips to crack JEE 2024"}
            </p>
          </Link>
        </h3>

        <p className="text-sm line-clamp-5">{blogItem?.teaser}</p>

        <div className="flex flex-row mt-1 md:items-end sm:items-center grow">
          <div className="flex items-center mr-2 text-xs capitalize xl:text-sm mb-10px">
            <span className="mr-1 leading-4 xl:leading-18px">By</span>
            <a className="mr-2 font-bold leading-3 hover:underline xl:leading-18px">
              {blogItem?.updateUser || blogItem?.createdUser?.firstName}
            </a>
            <span className="leading-4 whitespace-nowrap">
              {blogItem?.createdUser?.role || "Senior Content Writer"}
            </span>
          </div>
          <div className="hidden socialMedia-icon-block xs:hidden sm:hidden md:block">
            <ul className="flex items-center no-bullets blog-page-card__content-icons">
              <li className="pr-8 cursor-default group blog-page-card__content-icon-share">
                <img src={ShareIcon} alt="" height="20" width="20" />
              </li>
              <li className="flex items-center pr-8 group icon-hidden blog-page-card__content-icon-twitter">
                {/* <button className="flex bg-transparent blog-page-card-share focus:outline-none">
                  <FontAwesomeIcon
                    icon={faTwitter}
                    className="text-gray-400 hover:text-[#1d9bf0] text-md"
                  />
                </button> */}
                <TwitterShareButton url={`${config.apiUrl}${link}`}>
                  <button className="flex blog-page-card-share focus:outline-none">
                    <FontAwesomeIcon
                      icon={faXTwitter}
                      className="text-gray-400  hover:text-[#1d9bf0] text-md"
                    />
                  </button>
                </TwitterShareButton>
              </li>
              <li
                className="flex items-center pr-8 group icon-hidden blog-page-card__content-icon-facebook"
                data-value="facebook"
              >
                <FacebookShareButton url={`${config.apiUrl}${link}`}>
                  <button className="flex blog-page-card-share focus:outline-none">
                    <FontAwesomeIcon
                      icon={faFacebookF}
                      className="text-gray-400 hover:text-[#365899] text-md"
                    />
                  </button>
                </FacebookShareButton>
              </li>
              <li
                className="flex items-center pr-8 group icon-hidden blog-page-card__content-icon-linkedin"
                data-value="linkedin"
              >
                <LinkedinShareButton url={`${config.apiUrl}${link}`}>
                  <button className="flex blog-page-card-share focus:outline-none">
                    <FontAwesomeIcon
                      icon={faLinkedinIn}
                      className="text-gray-400  hover:text-[#0a66c2] text-md"
                    />
                  </button>
                </LinkedinShareButton>
              </li>
              <li
                className="flex items-center pr-8 group icon-hidden blog-page-card__content-icon-linkedin"
                data-value="linkedin"
              >
                <WhatsappShareButton url={`${config.apiUrl}${link}`}>
                  <button className="flex blog-page-card-share focus:outline-none">
                    <FontAwesomeIcon
                      icon={faWhatsapp}
                      className="text-gray-400  hover:text-[#22d366] text-md"
                    />
                  </button>
                </WhatsappShareButton>
              </li>
              <li
                className="flex items-center pr-6 group icon-hidden blog-page-card__content-icon-email"
                data-value="email"
              >
                <EmailShareButton url={`${config.apiUrl}${link}`}>
                  <button className="flex blog-page-card-share focus:outline-none">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="text-gray-400  hover:text-[#ffc000] text-md"
                    />
                  </button>
                </EmailShareButton>
              </li>
            </ul>
          </div>

          <div className="flex items-center justify-end visible grow socialMedia-icon-block-responsive xs:block sm:block md:hidden">
            <div className="listing_card_dropdown ">
              <Popover content={<SocialMediaMenuList link="" />}>
                <button className="" name="Share news">
                  <img src={MeatBallMenuIcon} alt="menu" width="20" />
                </button>
              </Popover>
            </div>
          </div>
        </div>
        <span className="block text-xs leading-6">
          {formattedDate(blogItem?.updatedDate)}
        </span>
      </div>
    </div>
  );
};

export default BlogListItem;
